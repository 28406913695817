import React from 'react';

interface Props {
  backgroundImageUrl: string;
}
export const Mobile = ({ backgroundImageUrl }: Props) => {
  return (
    <div className="dn-ns dn-m dn-l">
      <div className="h3 bg-top cover" style={{ backgroundImage: `url(${backgroundImageUrl})` }} />
    </div>
  );
};
