import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../../components/Icons';

interface Props {
  name: string;
  appearDesktop?: boolean;
}
export const LogoIcon = ({ name, appearDesktop = false, ...props }: Props) => {
  const containerIcon = cx('dn-m dn-l flex justify-center items-center mb40 pt32', {
    'flex-m flex-l': appearDesktop,
  });
  if (!name) {
    return null;
  }
  return (
    <div className={containerIcon}>
      <Icon name={name} {...props} />
    </div>
  );
};
