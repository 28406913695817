import React from 'react';

interface Props {
  backgroundImageUrl: string;
}
export const Desktop = ({ backgroundImageUrl }: Props) => {
  return (
    <div className="dn db-m dn-l">
      <div
        className="vh-50 bg-left cover"
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      />
    </div>
  );
};
