import React from 'react';
import cx from 'classnames';
import styles from './Banner.module.css';

interface Props {
  backgroundImageUrl: string;
}
export const Tablet = ({ backgroundImageUrl }: Props) => {
  return (
    <div className="dn db-ns dn-m dn-l">
      <div
        className={cx('bg-top cover', styles.tablet)}
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      />
    </div>
  );
};
