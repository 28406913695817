import React from 'react';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import { Retina } from './Retina';
import { Tablet } from './Tablet';

export const Banner = () => {
  const backgroundUrl = `${process.env.FTP_IMAGENS}images/login/fundo-login-1366x410.jpg`;
  const backgroundUrlLarge = `${process.env.FTP_IMAGENS}images/login/fundo-login-1920-x-410.jpg`;
  return (
    <>
      <Mobile backgroundImageUrl={backgroundUrl} />
      <Tablet backgroundImageUrl={backgroundUrl} />
      <Desktop backgroundImageUrl={backgroundUrl} />
      <Retina backgroundImageUrl={backgroundUrlLarge} />
    </>
  );
};
