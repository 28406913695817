import React from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { LinkBlack } from '../../../../components/Link';
import styles from './CardContainer.module.css';

interface Props {
  children?: React.ReactNode;
}
export class ContainerForgotPassword extends React.Component<Props> {
  navigateHome = () => navigate('/');

  render() {
    const container = cx('h-100 w-100 absolute flex justify-center', styles.containerForgotRoot);
    const containerForm = cx('w-100 relative', styles.containerForgotPassword);
    const link = cx('ttu fw6 mb4 mb8-ns db pl16 pl72-ns pl0-m pl0-l pointer', styles.link);

    const wrapperForm = cx(
      'h-100 bg-white br10 relative pt48-ns pt40-m pt40-l',
      styles.shadowWrapperForgot,
    );
    const { children } = this.props;
    if (!children) {
      return null;
    }
    return (
      <div className={container}>
        <div className={containerForm}>
          <LinkBlack
            className={link}
            onClick={this.navigateHome}
            title="Voltar para a página inicial"
            tabIndex={0}
          >
            voltar ao início
          </LinkBlack>
          <div className={wrapperForm}>{children}</div>
        </div>
      </div>
    );
  }
}
