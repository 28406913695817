import React from 'react';
import cx from 'classnames';
import styles from './Banner.module.css';

interface Props {
  backgroundImageUrl: string;
}
export const Retina = ({ backgroundImageUrl }: Props) => {
  const classRetina = cx('vh-50 bg-right', styles.retina);

  return (
    <div className="dn db-l">
      <div className={classRetina} style={{ backgroundImage: `url(${backgroundImageUrl})` }} />
    </div>
  );
};
